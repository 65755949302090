export function makeHeader(title) {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }
    return _title.join(" ");
}

export function makeAccessor(key) {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key;
    }
}

export function prepareFilterQuery(filters, columns, filterPrefix = '') {
    let query = '';

    filters.forEach((item) => {
        let filter = '';
        const _filterColumn = columns.filter((column) => column.accessor === item.id);

        if (_filterColumn?.length && _filterColumn[0].column.includes(".")) {
            filter = _filterColumn[0].column.split(".")[0] + "." + item.id;
        } else {
            filter = filterPrefix.length ? filterPrefix + "." + item.id : item.id;
        }

        query += `&contains[${filter}]=${item.value}`;
    });

    return query;
}

export function prepareSortQuery(sortBy, columns) {
    let sort = '';
    // Check sorting
    if (sortBy.length) {
        let sortByPrefix = "",
            sortByColumn = columns.filter((column) => column.accessor === sortBy[0].id);

        if (sortByColumn?.length) {
            if (sortByColumn[0].column.includes(".")) {
                sortByPrefix = sortByColumn[0].column.split(".")[0];
                sortByPrefix = `${sortByPrefix}.`;
            } else {

            }
        }

        sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${sortByPrefix}${sortBy[0].id}`;
    }

    return sort;
}