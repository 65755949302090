import {createRef, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {Button, Card, Chip, CircularProgress, Grid, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import {LinkedIn} from "@mui/icons-material";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {actions as contactsActions, selectors as contactsSelectors} from "../../../Ducks/contacts";
import {actions as eventsOverviewActions, selectors as eventsOverviewSelectors} from "../../../Ducks/eventsOverview";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import {useNavigate} from "react-router-dom";
import FormikFieldElement from "../../Common/FormikFieldElement";
import {getPendingPersonGroupChange} from "../../../API/changePersonGroup";
import TagHook from '../../Common/Tag';
import Swal from 'sweetalert2';
import {CURRENT, FUTURE, PRODUCT_TYPE_NETWORK_GROUP} from "../../Utils/constants";
import AlertElement from "../../Common/Alert";
import {checkIfPersonFoodConsiderationHasChanged} from "../../Utils/validations";

const useStyles = (theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    "paper": {
        padding: "16px",
        marginTop: 0,
        marginBottom: 10,
    },
});

const ContactForm = (props) => {
    const {
        saveData,
        classes,
        contactDetails,
        getPersonMiniProfile,
        personMiniProfile,
        profile_loading,
        foodConsiderations,
        personPurchases,
        getPersonPurchases,
        navigate,
        Tags,
        canManageGroupChange
    } = props;
    const [editable, setEditable] = useState(false);
    const [currentPurchase, setCurrentPurchase] = useState(null);
    const [showChangeGroup, setShowChangeGroup] = useState(false);
    const [fieldList, _setFieldList] = useState([
        {
            title: "First Name",
            name: "person_firstname",
            type: "textfield",
        },
        {
            title: "Last Name",
            name: "person_lastname",
            type: "textfield",
        },
        {
            title: "Gender",
            name: "person_gender",
            type: "dropdown",
            list: [
                {
                    id: "male",
                    name: "Male",
                },
                {
                    id: "female",
                    name: "Female",
                },
                {
                    id: "unisex",
                    name: "Unisex",
                },
            ],
        },
        {
            title: "Mobile Number",
            name: "person_mobile_number",
            type: "textfield",
        },
        {
            title: "Food Considerations",
            name: ["person_food_considerations_1", "person_food_considerations_2", "person_food_considerations_other"],
            type: "foodconsideration",
            options: props.foodConsiderations,
        },
        {
            title: "Special Conditions",
            name: "person_special_conditions",
            type: "textfield",
        },
        {
            title: "Linkedin Member ID",
            name: "person_linkedin_member_id",
            type: "textfield",
            disabled: true,
            disableButton: true,
        },
        {
            title: "Lead Source",
            name: "lead_source",
            type: "dropdown",
            list: [
                {
                    id: "LinkedIn",
                    name: "LinkedIn",
                },
                {
                    id: "ND List",
                    name: "ND List",
                },
                {
                    id: "PI List",
                    name: "PI List",
                },
                {
                    id: "Unknown Mailaddress",
                    name: "Unknown Mailaddress",
                },
                {
                    id: "The Right People",
                    name: "The Right People",
                },
            ],
        },
        {
            title: 'Limit Contact',
            name: 'person_preferences_limit_calls',
            type: 'checkbox'
        },
        {
            title: "Never SMS",
            name: "person_preferences_never_text",
            type: "checkbox",
        },
        {
            title: "Never Contact",
            name: "person_never_contact",
            type: "checkbox",
        },
        {
            title: "LinkedIn Url",
            name: "person_linkedin_url",
            type: "textfield",
        },
        {
            title: 'Tags',
            name: 'tags',
            type: 'autocomplete'
        }
    ]);
    const [miniProfileList, _setMiniProfileList] = useState([
        {
            title: "First Name",
            name: "mp_first_name",
        },
        {
            title: "Middle Name",
            name: "mp_middle_name",
        },
        {
            title: "Last Name",
            name: "mp_last_name",
        },
        {
            title: "Location",
            name: "mp_location_country",
        },
        {
            title: "Scan Time",
            name: "mp_scan_time",
        },
        {
            title: "Email",
            name: "mp_email",
        },
        {
            title: "Tags",
            name: "tags",
        },
        {
            title: "Primary Job titile",
            name: "mp_title",
        },
        {
            title: "Company",
            name: "company_name",
        },
        {
            title: "Industry",
            name: "mp_industry",
        },
        {
            title: "Scan URL",
            name: "mp_linkedin_url",
        },
    ]);
    const formRef = createRef();
    const initialData = {...contactDetails};

    // Handle checkboxes
    initialData.person_preferences_limit_calls = !!initialData.person_preferences_limit_calls;
    initialData.person_preferences_never_text = !!initialData.person_preferences_never_text;
    initialData.person_never_contact = !!initialData.person_never_contact;
    initialData.tags = initialData.tags;

    //Food consideration
    initialData.person_food_considerations_2 = initialData.person_food_considerations_2?.length == 0 ?
        [foodConsiderations?.type2_food?.find(item => item.id == 18)?.id] : initialData.person_food_considerations_2;
    initialData.person_food_considerations_1 = initialData.person_food_considerations_1?.length == "" ?
        foodConsiderations?.type1_food?.find(item => item.id == 17)?.id : initialData.person_food_considerations_1;

    useEffect(() => {
        // Get mini profile data
        contactDetails && getPersonMiniProfile(contactDetails.person_id);
        contactDetails && getPersonPurchases(contactDetails.person_id);
        contactDetails && getChangePersonGroup(contactDetails.person_id);
    }, [contactDetails]);

    const handlePersonEditing = () => {
        setEditable(!editable);
    };

    const handleSubmitData = () => {
        if (formRef.current) {
            if (formRef.current.values.person_food_considerations_1 === "" || formRef.current.values.person_food_considerations_2.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Food consideration can not be empty",
                });
            } else {
                const personFoodConsiderationHasChanged =
                    checkIfPersonFoodConsiderationHasChanged(initialData, formRef, foodConsiderations, 'contact');

                if (!personFoodConsiderationHasChanged) {
                    formRef.current.handleSubmit();
                }
            }
        }
    };

    const parseData = (field) => {
        if (field === "person_preferences_limit_calls" || field === "person_preferences_never_text" || field === "person_never_contact") {
            return contactDetails[field] === 1 ? "Yes" : "No";
        } else if (Array.isArray(field)) {
            let foodConsiderationsText = foodConsiderations.type1_food.find(f => f.id == contactDetails["person_food_considerations_1"])?.title;
            return [
                foodConsiderationsText,
                foodConsiderations.type2_food
                    .filter(f => contactDetails["person_food_considerations_2"].includes(f.id))
                    .map(f => f.title)
                    .join(', '),
                contactDetails['person_food_considerations_other']
            ]
            .filter(Boolean)
            .join(', ');
        } else {
            return contactDetails[field];
        }
    };

    const parseMiniProfileData = (field) => {
        if (Array.isArray(personMiniProfile[field])) {
            return personMiniProfile[field].map((tag) => tag.tag_title);
        } else {
            return personMiniProfile[field];
        }
    };

    const handleChangeGroupClick = () => {
        contactDetails && navigate("/contact/id/" + contactDetails.person_id + "/change-group");
    };

    const getChangePersonGroup = (personId) => {
        getPendingPersonGroupChange(personId).then((res) => {
            if (res?.data?.person_id) {
                setShowChangeGroup(false);
            } else {
                setShowChangeGroup(true);
            }
        }).catch(() => {
            setShowChangeGroup(true);
        });
    }

    useEffect(() => {
        if (personPurchases) {
            let _currentPurchase = personPurchases.filter(
                (purchase) => (purchase.purchase_actual_status === CURRENT || purchase.purchase_actual_status === FUTURE)
                    && purchase.product_type_id === PRODUCT_TYPE_NETWORK_GROUP
            );

            if (_currentPurchase.length > 0) {
                setCurrentPurchase(_currentPurchase[0]);
            } else {
                setCurrentPurchase(null);
            }
        }
    }, [personPurchases]);

    const handleChange = (e) => {
        if (e.target.name === 'person_food_considerations_2') {
            let arr = e.target.value;
            formRef.current.setFieldValue('person_food_considerations_2', arr);

            if (arr.includes(18)) {
                let filterd = arr.filter(item => item !== 18);
                formRef.current.setFieldValue('person_food_considerations_2', filterd);

            } else if (!e.target.value.length) {
                formRef.current.setFieldValue('person_food_considerations_2', [18]);
            }
        }
    }

    return (
        <>
            <Card className={classes.paper}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div
                            style={{
                                width: 120,
                                height: 120,
                                marginRight: 10,
                                borderRadius: "50%",
                                backgroundImage: `url(https://erp.f5.dk/uploads/images/persons/${contactDetails.person_token}.jpg)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "50%",
                                border: "1px solid #eee",
                            }}
                        />
                        <div>
                            <Typography
                                variant='h6'
                                component='div'
                                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                {contactDetails.person_firstname} {contactDetails.person_lastname}{" "}
                                <a target='_blank' href={contactDetails.person_linkedin_url}>
                                    <LinkedIn sx={{display: "flex", color: "icon.main"}}/>
                                </a>
                            </Typography>
                            <Typography variant='body2' color='site.main'>
                                {contactDetails.title}
                            </Typography>
                            <Typography variant='body2' color='site.main' className={classes.flexVertical}>
                                <span style={{marginLeft: 0}}>{contactDetails.person_region}</span>
                            </Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        {editable ? (
                            <>
                                <Button
                                    color='success'
                                    variant='contained'
                                    size='small'
                                    style={{textTransform: "capitalize", marginRight: "5px"}}
                                    onClick={handleSubmitData}>
                                    Accept
                                </Button>
                                <Button
                                    color='error'
                                    variant='contained'
                                    size='small'
                                    style={{textTransform: "capitalize", marginRight: "5px"}}
                                    onClick={handlePersonEditing}>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                style={{textTransform: "capitalize", marginRight: "5px"}}
                                onClick={handlePersonEditing}>
                                Edit Person
                            </Button>
                        )}
                        {currentPurchase && showChangeGroup && canManageGroupChange && (
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                style={{textTransform: "capitalize"}}
                                onClick={() => handleChangeGroupClick()}>
                                Change Group
                            </Button>
                        )}
                    </div>
                </div>
            </Card>
            {
                editable && initialData.person_food_considerations_set_by_notice !== '' &&
                <AlertElement severity="info" message={initialData.person_food_considerations_set_by_notice}/>
            }
            <Card className={classes.paper}>
                {
                    editable ? (
                        <Formik
                            innerRef={formRef}
                            initialValues={initialData}
                            validate={_values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                const _data = {
                                    person_firstname: values.person_firstname,
                                    person_lastname: values.person_lastname,
                                    person_gender: values.person_gender,
                                    person_mobile_number: values.person_mobile_number,
                                    person_food_considerations_1: values.person_food_considerations_1,
                                    person_food_considerations_2: values.person_food_considerations_2,
                                    person_food_considerations_other: values.person_food_considerations_other,
                                    person_special_conditions: values.person_special_conditions,
                                    person_linkedin_member_id: values.person_linkedin_member_id,
                                    lead_source: values.lead_source,
                                    person_preferences_limit_calls: values.person_preferences_limit_calls,
                                    person_preferences_never_text: values.person_preferences_never_text,
                                    person_never_contact: values.person_never_contact,
                                    person_linkedin_url: values.person_linkedin_url,
                                    tags: values.tags,
                                }

                                saveData(_data);
                                setSubmitting(false);
                                setEditable(false);
                            }}
                            render={({values, setFieldValue}) => (
                                <Form style={{columnCount: 2}}>
                                    {
                                        fieldList.map((field, index) => {
                                            return (
                                                <Grid key={index} container spacing={2} sx={{
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
                                                    marginBottom: '10px'
                                                }}>
                                                    <Grid item xs={6} sx={{textAlign: 'right'}}>
                                                        {field.title}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {field.type === 'autocomplete' ? (
                                                            <TagHook key={index} tags={Tags} setValue={setFieldValue}
                                                                     selectedTags={initialData.tags} edit={false}/>
                                                        ) : (
                                                            field.type === 'foodconsideration' ? (
                                                                    <FormikFieldElement {...field} key={index}
                                                                                        formData={values}
                                                                                        onChange={handleChange}/>
                                                                ) :
                                                                <FormikFieldElement {...field} key={index}
                                                                                    formData={values}/>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Form>
                            )}
                        />
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{columnCount: 2}}>
                                    {
                                        fieldList.map((field, index) => {
                                            return (
                                                <Grid key={index} container spacing={4}
                                                      sx={{display: 'inline-flex', marginBottom: '10px'}}>
                                                    <Grid item xs={6} sx={{textAlign: 'right'}}>
                                                        <b>{field.title}</b>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {
                                                            field.type === 'autocomplete' ? (
                                                                initialData?.tags?.map(tag =>
                                                                    <Chip sx={{marginRight: 1, marginBottom: 1}}
                                                                          label={tag}/>
                                                                )
                                                            ) : parseData(field.name)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )
                }
            </Card>
            <Grid container spacing={2} sx={{marginBottom: 2, marginTop: 1}}>
                <Grid item xs={12}>
                    <Typography variant='h6' component='div'
                                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        Mini Profile
                    </Typography>
                </Grid>
            </Grid>
            {profile_loading ? (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <CircularProgress/>
                </div>
            ) : !isEmpty(personMiniProfile) ? (
                <Card className={classes.paper}>
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{columnCount: 2}}>
                                {miniProfileList.map((field, index) => {
                                    return (
                                        <Grid key={index} container spacing={4}
                                              sx={{display: "inline-flex", marginBottom: "10px"}}>
                                            <Grid item xs={6} sx={{textAlign: "right"}}>
                                                <b>{field.title}</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {Array.isArray(parseMiniProfileData(field.name))
                                                    ? parseMiniProfileData(field.name).map((tag) => (
                                                        <Chip sx={{marginRight: 1, marginBottom: 1}} label={tag}/>
                                                    ))
                                                    : parseMiniProfileData(field.name)}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </>
                </Card>
            ) : (
                <Card className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{textAlign: "center"}}>
                            Mini profile not found
                        </Grid>
                    </Grid>
                </Card>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    profile_loading: contactsSelectors.profile_loading(state),
    personMiniProfile: contactsSelectors.personMiniProfile(state),
    personPurchases: eventsOverviewSelectors.personPurchases(state),
    Tags: contactsSelectors.Tags(state),
    canManageGroupChange: accountSelectors.canManageGroupChange(state)
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPersonMiniProfile: contactsActions.getPersonMiniProfile,
            getPersonPurchases: eventsOverviewActions.getPersonPurchases,
        },
        dispatch
    );

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ContactForm {...props} navigate={navigate}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
