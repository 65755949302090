import React, {useCallback, useState} from "react";
import {makeAccessor, makeHeader, prepareFilterQuery, prepareSortQuery} from "../../Utils/gridUtils";
import {IconButton, Tooltip} from "@mui/material";
import {
    CalendarTodaySharp,
    PersonAddAlt1,
} from "@mui/icons-material";
import {
    PENDING
} from "../../Utils/constants";
import {getChangePersonJobs} from "../../../API/changePersonJob";
import {toast} from "react-toastify";
import GridElement from "../../Common/GidElement";
import DelayTransferMembershipModal from "./DelayTransferMembershipModal";
import TransferMembershipModal from "./TransferMembershipModal";

const TransferLaterGrid = () => {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [openDelayTransferMembershipModal, setOpenDelayTransferMembershipModal] = useState(false);
    const [changeJob, setChangeJob] = useState({});
    const [openTransferMembershipModal, setOpenTransferMembershipModal] = useState(false);
    const [refreshGridData, setRefreshGridData] = useState(false);
    const [cols, _setCol] = useState([
        {
            column: "person_job_changes.person_job_change_id",
            accessor: makeAccessor("person_job_change_id"),
            minWidth: 150,
            Header: makeHeader("person_job_change_id"),
            filter: "fuzzyText",
        },
        {
            column: "person_job_changes.purchase_id",
            accessor: makeAccessor("person_job_changes.purchase_id"),
            minWidth: 150,
            Header: makeHeader("person_job_changes.purchase_id"),
            filter: "fuzzyText",
        },
        {
            column: "person_name",
            accessor: makeAccessor("person_name"),
            minWidth: 150,
            Header: makeHeader("person_name"),
            filter: "fuzzyText",
        },
        {
            column: "jobs.job_title",
            accessor: makeAccessor("jobs.job_title"),
            minWidth: 150,
            Header: makeHeader("jobs.job_title"),
            filter: "fuzzyText",
        },
        {
            column: "companies.company_name",
            accessor: makeAccessor("companies.company_name"),
            minWidth: 150,
            Header: makeHeader("companies.company_name"),
            filter: "fuzzyText",
        },
        {
            column: "person_job_changes.status",
            accessor: makeAccessor("person_job_changes.status"),
            minWidth: 150,
            Header: makeHeader("person_job_changes.status"),
            filter: "fuzzyText",
        },
        {
            column: "person_job_changes.tentative_transfer_date",
            accessor: makeAccessor("person_job_changes.tentative_transfer_date"),
            minWidth: 150,
            Header: makeHeader("person_job_changes.tentative_transfer_date"),
            filter: "fuzzyText",
        },
        {
            column: "initiated_by",
            accessor: makeAccessor("initiated_by"),
            minWidth: 150,
            Header: makeHeader("Initiated By"),
            filter: "fuzzyText",
        },
        {
            column: "created_at",
            accessor: makeAccessor("created_at"),
            minWidth: 150,
            Header: makeHeader("Cancelled Date"),
            filter: "fuzzyText",
        },
        {
            minWidth: 150,
            Header: makeHeader("Actions"),
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    {
                        row.original.status === PENDING &&
                        <>
                            <Tooltip title='Delay Transfer' arrow placement='top'>
                                <IconButton
                                    aria-label='delay transfer'
                                    aria-haspopup='true'
                                    onClick={() => {
                                        setOpenDelayTransferMembershipModal(true);
                                        setChangeJob(row.original);
                                    }}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                >
                                    <CalendarTodaySharp color='primary'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Transfer' arrow placement='top'>
                                <IconButton
                                    aria-label='transfer membership'
                                    aria-haspopup='true'
                                    onClick={() => {
                                        setOpenTransferMembershipModal(true);
                                        setChangeJob(row.original);
                                    }}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                >
                                    <PersonAddAlt1 color='primary'/>
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </div>
            )
        },
    ]);

    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        let _columns = columns.map((item) => item.column);
        _columns = _columns.filter((item) => item !== undefined);

        let query = "",
            sort = "";

        query = query + prepareFilterQuery(filters, columns);
        sort = prepareSortQuery(sortBy, columns);

        setLoading(true);

        getChangePersonJobs({
            results: pageSize,
            columns: _columns,
            offset: pageIndex + 1,
            filter: query,
            sort: sort
        }).then(res => {
            setData(res.data.main);
            setPageCount(res.data.offset.highest_offset);
            setRowsCount(res.data.offset.rows_count);
            setLoading(false);
        }).catch((error) => {
            toast.error(error);
            setLoading(false);
        })

        setRefreshGridData(false); //if grid fetches latest data then set refreshGridData to false
    }, []);

    const resetFilters = useCallback(() => setFilters([]), [setFilters]);

    const handleDelayTransferMembershipClose = (refreshGrid = false) => {
        setOpenDelayTransferMembershipModal(false);
        setRefreshGridData(refreshGrid);
    };

    const handleTransferMembershipClose = (refreshGrid = false) => {
        setOpenTransferMembershipModal(false);
        setRefreshGridData(refreshGrid);
    };

    return (
        <>
            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={() => {
                }}
                initialSortBy='created_at'
                sortOrder='desc'
                hideSelection={true}
                refreshGridData={refreshGridData}
            />
            <DelayTransferMembershipModal
                changeJob={changeJob}
                open={openDelayTransferMembershipModal}
                handleClose={handleDelayTransferMembershipClose}/>
            <TransferMembershipModal
                open={openTransferMembershipModal}
                handleClose={handleTransferMembershipClose}
                personId={changeJob.person_id}
                transferLater={true}
                personChangeJobId={changeJob.person_job_change_id}/>
        </>
    );
}

export default TransferLaterGrid;