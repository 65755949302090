import {Autocomplete, Button, Card, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import {LoadingButton} from "@mui/lab";
import EventGrid from "../ChangePersonGroup/EventGrid";
import {checkFutureEvent} from "../../Utils/dateUtils";

const TransferMembershipOverview = (props) => {
    const {
        products,
        person,
        newPerson,
        product,
        processing,
        handleProcessClick,
        handleCancelClick,
        setProductCallback,
        newEvents
    } = props;
    const [newProduct, setNewProduct] = useState(null);
    const [meetingsCount, setMeetingsCount] = useState(0);
    const [newProductUpcomingEvents, setNewProductUpcomingEvents] = useState([]);

    useEffect(() => {
        if (product && products) {
            const selectedProduct = products.find((productItem) => productItem.product_id === product.product_id);

            setNewProduct(selectedProduct);
        }
    }, [product, products]);

    useEffect(() => {
        if (newEvents) {
            setNewProductUpcomingEvents(newEvents);
            setMeetingsCount(newEvents.length)
        }
    }, [newEvents]);

    const fetchNewProductUpcomingEvents = (events, meetingsLeft) => {
        let upcomingEvents = [];

        events.some((event) => {
            if (checkFutureEvent(event)
                && upcomingEvents.length < meetingsLeft
            ) {
                upcomingEvents.push(event);
            }
            return false;
        });

        return upcomingEvents
    }

    const disableProcessButton = () => {
        return !newPerson || !newProduct || !newEvents;
    }

    return (
        <>
            <Grid container spacing={2} sx={{padding: "10px"}}>
                <Grid item xs={6}>
                    <Typography variant="h6" sx={{paddingBottom: "10px"}}>
                        Existing Member
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            Person Name:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.person_firstname} {person?.person_lastname}
                        </Grid>
                        <Grid item xs={6}>
                            Mobile Number:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.person_mobile_number}
                        </Grid>
                        <Grid item xs={6}>
                            Company Name:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.company_name}
                        </Grid>
                        <Grid item xs={6}>
                            Job Title:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.job_title}
                        </Grid>
                        <Grid item xs={6}>
                            Job Email:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.job_email}
                        </Grid>

                        <Grid item xs={6}>
                            Job Phone:
                        </Grid>
                        <Grid item xs={6}>
                            {person?.job_phone}
                        </Grid>
                        <Grid item xs={6}>
                            LinkedIn Profile:
                        </Grid>
                        <Grid item xs={6}>
                            <a href={person?.person_linkedin_url}
                               target="_blank">{person?.person_linkedin_member_id}</a>
                        </Grid>
                        <Grid item xs={6}>
                            Limit Contact:
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={person.person_preferences_limit_calls === 1} disabled={true}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" sx={{paddingBottom: "10px"}}>
                        New Member
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            Person Name:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.person_firstname} {newPerson?.person_lastname}
                        </Grid>
                        <Grid item xs={6}>
                            Mobile Number:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.person_mobile_number}
                        </Grid>
                        <Grid item xs={6}>
                            Company Name:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.company_name}
                        </Grid>
                        <Grid item xs={6}>
                            Job Title:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.job_title}
                        </Grid>
                        <Grid item xs={6}>
                            Job Email:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.job_email}
                        </Grid>
                        <Grid item xs={6}>
                            Job Phone:
                        </Grid>
                        <Grid item xs={6}>
                            {newPerson?.job_phone}
                        </Grid>
                        <Grid item xs={6}>
                            LinkedIn Profile:
                        </Grid>
                        <Grid item xs={6}>
                            <a href={newPerson?.person_linkedin_url}
                               target="_blank">{newPerson?.person_linkedin_member_id}</a>
                        </Grid>
                        <Grid item xs={6}>
                            Limit Contact:
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={newPerson.person_preferences_limit_calls === 1} disabled={true}/>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{padding: "10px"}}>
                <Grid item xs={12}>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Autocomplete
                                    name='newProduct'
                                    margin='normal'
                                    size='small'
                                    id='products'
                                    disablePortal
                                    disableClearable
                                    options={products}
                                    value={newProduct}
                                    getOptionLabel={(option) => option.product_name}
                                    onChange={(_event, newValue) => {
                                        console.log(newValue);
                                        setNewProduct(newValue);

                                        const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newValue.events, meetingsCount);

                                        setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                        setProductCallback(newValue, _newProductUpcomingEvents);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Products'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='number'
                                    label='Meetings Count'
                                    variant='outlined'
                                    margin='normal'
                                    size='small'
                                    required
                                    fullWidth
                                    inputProps={{min: 1, max: 5}}
                                    value={meetingsCount}
                                    onInput={(event) => {
                                        const {value} = event.target;

                                        setMeetingsCount(value.replace(/[^0-9]/g, ""));// Remove any character that is not a digit

                                        if (newProduct?.events) {
                                            const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newProduct.events, value);

                                            setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                            setProductCallback(newProduct, _newProductUpcomingEvents);
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        const {key, target} = event;
                                        const {value} = target;

                                        if (key === "Backspace" || key === "Delete") {
                                            event.preventDefault();
                                        } else {
                                            // Prevent entering values greater than 5
                                            if (parseFloat(value + key) > 5) {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        newProductUpcomingEvents?.length > 0 &&
                        <Grid item xs={6}>
                            <Card style={{padding: "12px"}}>
                                <Typography variant='h6' component='h6'>
                                    Upcoming events ({newProduct?.product_name})
                                </Typography>
                                <EventGrid events={newProductUpcomingEvents}/>
                            </Card>
                        </Grid>
                    }

                </Grid>
            </Grid>
            <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button type='submit'
                            variant='contained'
                            color='secondary'
                            onClick={handleCancelClick}
                            style={{marginTop: "10px", marginRight: "6px"}}
                            disabled={processing}
                    >
                        Cancel
                    </Button>
                    <LoadingButton type='submit'
                                   variant='contained'
                                   color='primary'
                                   onClick={handleProcessClick}
                                   loading={processing}
                                   style={{marginTop: "10px", marginRight: "5px"}}
                                   disabled={disableProcessButton()}
                    >
                        Process
                    </LoadingButton>
                </Grid>
            </Grid>
        </>

    )
};

export default TransferMembershipOverview;