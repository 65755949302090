import {
    Box,
    Button,
    FormControl,
    FormControlLabel, Grid,
    Radio,
    RadioGroup, StepLabel
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from "react";
import {
    DUPLICATE_MEMBERSHIP, TRANSFER_LATER,
    TRANSFER_LATER_CANCEL_NOW,
    TRANSFER_MEMBERSHIP,
    TRANSFER_NOW
} from "../../Utils/constants";
import PersonGrid from "./PersonGrid";
import TransferMembershipOverview from "./TransferMembershipOverview";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import {changeJob} from "../../../API/changePersonJob";
import {toast} from "react-toastify";
import TransferLater from "./TransferLater";
import {checkFutureEvent} from "../../Utils/dateUtils";

const TransferMembershipStepper = (props) => {
    const {
        products,
        person,
        product,
        purchase,
        handleCancelClick,
        transferLater,
        personChangeJobId
    } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [changeJobType, setChangeJobType] = useState(TRANSFER_MEMBERSHIP);
    const [transferType, setTransferType] = useState(transferLater ? TRANSFER_LATER : TRANSFER_NOW);
    const [newPerson, setNewPerson] = useState(null);
    const [newProduct, setNewProduct] = useState(null);
    const [newEvents, setNewEvents] = useState([]);
    const [processing, setProcessing] = useState(false);
    const transferMembershipSteps = ["Select Change Job Type", "Select Transfer Type", "Select Member", "Transfer Overview"];
    const transferLaterMembershipSteps = ["Select Change Job Type", "Select Transfer Type"];
    const duplicateMembershipSteps = ["Select Change Job Type", "Select Member", "Transfer Overview"];
    const transferLaterSteps = ["Select Member", "Transfer Overview"];
    const [steps, setSteps] = useState(transferMembershipSteps);

    useEffect(() => {
        if (product && products && purchase) {
            setNewProduct(product);

            const selectedProduct = products.find((productItem) => productItem.product_id === product.product_id);
            let meetingsLeft = purchase.upcoming_events?.length;

            if (transferLater) {
                meetingsLeft = 5;
            }

            let upcomingEvents = [];

            selectedProduct?.events?.forEach((event) => {
                if (checkFutureEvent(event)
                    && upcomingEvents.length < meetingsLeft
                ) {
                    upcomingEvents.push(event);
                }
                return false;
            });

            setNewEvents(upcomingEvents);
        }

        if (transferLater) {
            setSteps(transferLaterSteps);
        }
    }, [product, products, purchase, transferLater]);

    const handleNext = () => {
        if (transferLater && activeStep === 0) {
            if (newPerson) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (changeJobType === TRANSFER_MEMBERSHIP && activeStep === 2) {
            if (newPerson) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePersonSelection = (selected) => {
        if (selected.length) {
            setNewPerson(selected[0]);
        } else {

            setNewPerson(null);
        }
    }

    const disableNext = () => {
        if (transferLater && activeStep === 0) {
            return !newPerson;
        } else if (changeJobType === TRANSFER_MEMBERSHIP && activeStep === 2) {
            return !newPerson;
        } else if (changeJobType === DUPLICATE_MEMBERSHIP && activeStep === 1) {
            return !newPerson;
        }

        return false;
    }

    const handleProcessClick = () => {
        let postData = {};
        postData.change_job_type = changeJobType;
        postData.transfer_type = transferType;
        postData.product = newProduct;
        postData.new_person_id = newPerson.person_id;
        postData.job_id = newPerson.job_id;
        postData.person_change_job_id = personChangeJobId;
        postData.new_events = newEvents?.map(({
                                                  event_to_product_id,
                                                  event_id,
                                                  event_name,
                                                  event_start,
                                                  event_stop
                                              }) => ({
            event_to_product_id,
            event_id,
            event_name,
            event_start,
            event_stop
        }));

        setProcessing(true);
        changeJob(person.person_id, postData).then((res) => {
            if (res) {
                toast.success(res.message);
                handleCancelClick(true);
            }
            setProcessing(false);
        }).catch((error) => {
            toast.error(error.message);
            setProcessing(false);
        });
    }

    const setProductCallback = (data, events) => {
        setNewProduct(data);
        setNewEvents((events));
    }

    return (
        <Box>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 2,
                    mb: 6,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{mr: 1}}
                    >
                        Back
                    </Button>
                    <Box sx={{flex: '1 1 auto'}}/>
                    {activeStep < steps.length - 1 &&
                        <Button
                            variant="contained"
                            onClick={handleNext} disabled={disableNext()}>
                            Next
                        </Button>
                    }
                </Box>
                {
                    activeStep === 0 && (
                        transferLater ? (
                                <div style={{width: "100%", height: "600px"}}>
                                    <PersonGrid selectable={true} selectableCount={1} handleSelected={handlePersonSelection}
                                                products={products}/>
                                </div>
                            ) :
                            <div style={{height: "600px"}}>
                                <div style={{display: "flex", justifyContent: "center"}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='changeJobType'
                                            value={changeJobType}
                                            onChange={(event) => {
                                                setChangeJobType(event.target.value);

                                                if (event.target.value === DUPLICATE_MEMBERSHIP) {
                                                    setSteps(duplicateMembershipSteps);
                                                } else if (event.target.value === TRANSFER_MEMBERSHIP) {
                                                    setSteps(transferMembershipSteps);
                                                }
                                            }}
                                        >
                                            <FormControlLabel value={TRANSFER_MEMBERSHIP} control={<Radio/>}
                                                              label={TRANSFER_MEMBERSHIP}/>
                                            <FormControlLabel value={DUPLICATE_MEMBERSHIP}
                                                              control={<Radio/>}
                                                              label={DUPLICATE_MEMBERSHIP}/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>)
                }
                {
                    activeStep === 1 && (
                        transferLater ?
                            (
                                <TransferMembershipOverview products={products} person={person} newPerson={newPerson}
                                                            product={product}
                                                            purchase={purchase}
                                                            handleProcessClick={handleProcessClick}
                                                            handleCancelClick={handleCancelClick}
                                                            setProductCallback={setProductCallback}
                                                            newEvents={newEvents}/>
                            )
                            :
                            (
                                changeJobType === TRANSFER_MEMBERSHIP ? (
                                        <div style={{height: "600px"}}>
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby='demo-row-radio-buttons-group-label'
                                                        name='changeJobType'
                                                        value={transferType}
                                                        onChange={(event) => {
                                                            setTransferType(event.target.value)

                                                            if (event.target.value === TRANSFER_LATER_CANCEL_NOW) {
                                                                setSteps(transferLaterMembershipSteps);
                                                            } else if (event.target.value === TRANSFER_NOW) {
                                                                setSteps(transferMembershipSteps);
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel value={TRANSFER_NOW}
                                                                          control={<Radio id="transfer_now"/>}
                                                                          label={TRANSFER_NOW}/>
                                                        <FormControlLabel value={TRANSFER_LATER_CANCEL_NOW}
                                                                          control={<Radio id="transfer_later_cancel_now"/>}
                                                                          label={TRANSFER_LATER_CANCEL_NOW}/>
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            {
                                                transferType === TRANSFER_LATER_CANCEL_NOW &&
                                                <TransferLater person={person}
                                                               purchase={purchase} handleCancelClick={handleCancelClick}/>
                                            }
                                        </div>
                                    )
                                    : (
                                        <div style={{width: "100%", height: "600px"}}>
                                            <PersonGrid selectable={true} selectableCount={1}
                                                        handleSelected={handlePersonSelection}
                                                        products={products}/>
                                        </div>
                                    )
                            ))
                }
                {
                    activeStep === 2 && (
                        changeJobType === TRANSFER_MEMBERSHIP ? (
                                <div style={{width: "100%", height: "600px"}}>
                                    <PersonGrid selectable={true} selectableCount={1}
                                                handleSelected={handlePersonSelection}
                                                products={products}/>
                                </div>) :
                            <TransferMembershipOverview products={products} person={person} newPerson={newPerson}
                                                        product={product}
                                                        purchase={purchase}
                                                        handleProcessClick={handleProcessClick}
                                                        handleCancelClick={handleCancelClick}
                                                        setProductCallback={setProductCallback}
                                                        newEvents={newEvents}
                                                        processing={processing}/>
                    )
                }
                {
                    activeStep === 3 &&
                    changeJobType === TRANSFER_MEMBERSHIP &&
                    <TransferMembershipOverview products={products} person={person} newPerson={newPerson}
                                                product={product}
                                                purchase={purchase}
                                                handleProcessClick={handleProcessClick}
                                                handleCancelClick={handleCancelClick}
                                                setProductCallback={setProductCallback}
                                                newEvents={newEvents}
                                                processing={processing}/>
                }
            </>
        </Box>
    );
}

export default TransferMembershipStepper;
                       